<template>
  <div class="tw-flex tw-items-top">
    <div class="tw-space-x-1">
      <span>{{ attachmentDate }}</span>

      <span v-if="in24HourClock"> {{ date.format('HH:mm') }} </span>

      <span v-else> {{ date.format('LT') }} </span>
    </div>
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'AttachmentDate',

  mixins: [FormatDate],

  props: {
    date: {
      type: Object,
      required: true,
    },
  },

  computed: {
    attachmentDate() {
      return this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
        this.date
      )
    },

    in24HourClock() {
      return this.authUser.settings.in_24_hour_clock
    },
  },
}
</script>
