export default {
  dayReadableNumberMonthYear: {
    dateOnly: true,
    mdy: 'MMMM DD YYYY',
    dmy: 'DD MMMM YYYY',
    ymd: 'YYYY MMMM DD',
  },

  numberDayNumberMonthYearWithSlashAndReadableDay: {
    dateOnly: true,
    mdy: 'MM/DD/YYYY ddd',
    dmy: 'DD/MM/YYYY ddd',
    ymd: 'YYYY/MM/DD ddd',
  },

  formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash: {
    dateOnly: true,
    mdy: 'MM/DD/YYYY',
    dmy: 'DD/MM/YYYY',
    ymd: 'YYYY/MM/DD',
  },

  formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithDash: {
    dateOnly: true,
    mdy: 'MM-DD-YYYY',
    dmy: 'DD-MM-YYYY',
    ymd: 'YYYY-MM-DD',
  },

  dayReadableDayNumberShortMonthYear: {
    dateOnly: true,
    mdy: 'ddd MMM DD YYYY',
    dmy: 'ddd DD MMM YYYY',
    ymd: 'ddd YYYY MMM DD',
  },

  dayReadableShortDayNumberShortMonthYear: {
    dateOnly: true,
    mdy: 'MMM DD YYYY',
    dmy: 'DD MMM YYYY',
    ymd: 'YYYY MMM DD',
  },

  dayReadableShortDayNumberShortMonth: {
    dateOnly: true,
    mdy: 'MMM DD',
    dmy: 'DD MMM',
    ymd: 'MMM DD',
  },

  dayReadableShortDayNumberWithPlaceMonth: {
    dateOnly: true,
    mdy: 'MMMM Do',
    dmy: 'Do MMMM',
    ymd: 'MMMM Do',
  },

  shortDateHumanReadable: {
    dateOnly: false,
    mdy: {
      in24Hour: 'MMM DD YYYY HH:mm',
      in12Hour: 'MMM DD YYYY h:mma',
    },
    dmy: {
      in24Hour: 'DD MMM YYYY HH:mm',
      in12Hour: 'DD MMM YYYY h:mma',
    },
    ymd: {
      in24Hour: 'YYYY MMM DD HH:mm',
      in12Hour: 'YYYY MMM DD h:mma',
    },
  },

  shortDateHumanReadableWithAt: {
    dateOnly: false,
    mdy: {
      in24Hour: 'MMM DD YYYY [at] HH:mm',
      in12Hour: 'MMM DD YYYY [at] h:mma',
    },
    dmy: {
      in24Hour: 'DD MMM YYYY [at] HH:mm',
      in12Hour: 'DD MMM YYYY [at] h:mma',
    },
    ymd: {
      in24Hour: 'YYYY MMM DD [at] HH:mm',
      in12Hour: 'YYYY MMM DD [at] h:mma',
    },
  },

  hourMinutes: {
    dateOnly: false,
    mdy: {
      in24Hour: 'HH:mm',
      in12Hour: 'h:mma',
    },
    dmy: {
      in24Hour: 'HH:mm',
      in12Hour: 'h:mma',
    },
    ymd: {
      in24Hour: 'HH:mm',
      in12Hour: 'h:mma',
    },
  },
}
